import api from "./api-service";
import { stringify } from "querystring";

const baseUrl = "legends";

export const remove = (id) => api().delete(`${baseUrl}/delete/${id}`);

export const find = (filter) => api().get(`${baseUrl}?${stringify(filter)}`);

export const findOne = (id) => api().get(`${baseUrl}/findOne/${id}`);

export const save = (data) => api().post(`${baseUrl}`, data);
