<template>
  <v-container>
    <perseu-card title="Gerenciar Legendas">
      <v-btn color="secondary" slot="title-right" @click="create">
        Nova Legenda
      </v-btn>
      <v-flex slot="content">
        <filters-panel class="mb-3">
          <v-row>
            <v-col md="6" cols="12">
              <v-text-field
                hide-details
                v-model="query.acronym"
                filled
                label="Sigla"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn color="secondary" @click="list"> Buscar </v-btn>
          </v-row>
        </filters-panel>
        <v-data-table :items="legends" :headers="headers" class="elevation-1">
          <template #item.actions="props">
            <v-btn small icon @click="edit(props.item)" color="secondary">
              <v-icon small>edit</v-icon>
            </v-btn>
            <v-btn icon @click="remove(props.item)" color="secondary">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
    <legend-form ref="form" @create="addToList" @update="replaceLegend" />
  </v-container>
</template>

<script>
import { find, findOne, remove } from "@/services/legends-service";

export default {
  components: {
    "legend-form": () => import("@/components/Legends/Form"),
  },
  data: () => ({
    headers: [
      { text: "Sigla", value: "acronym" },
      { text: "Significado", value: "explanation" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    query: {
      acronym: "",
    },
    legends: [],
    legend: {},
    dialog: false,
  }),
  created() {
    this.legend = {};
    this.dialog = false;
    this.list();
  },
  methods: {
    create() {
      this.$refs.form.create();
      this.dialog = true;
    },
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await find(this.getFilter());
        this.legends = response.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    getFilter() {
      let filter = {};
      filter.acronym = this.query.acronym;
      return filter;
    },
    addToList(legend) {
      this.legends.push(legend);
      this.dialog = false;
      this.legend = {};
    },
    async edit(item) {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await findOne(item.id);
        this.$refs.form.edit(data);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async remove(legend) {
      try {
        this.$store.dispatch("loading/openDialog");
        let index = this.legends.indexOf(legend);
        this.legends.splice(index, 1);
        await remove(legend.id);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    replaceLegend(editedLegend) {
      const index = this.legends.findIndex(
        (legend) => legend.id === editedLegend.id
      );
      this.legends.splice(index, 1, editedLegend);
    },
  },
};
</script>
